import React from "react";
import cubeta from "../../assets/images/cubeta.png";
import Product from "../../types/Product";
import {Link, useNavigate} from "react-router-dom";
import TagsContainer from "./TagsContainer";

interface ProductCard {
    product: Product
}

const Card = ({product}: ProductCard) => {

    return (

        <div className={"outstanding-carousel-card"}>
            <div className={"card-icons"}>
                <TagsContainer tags={product?.tags} classNameToIcons={"landing-carousel-icon"}/>
            </div>
            <div className={"card-content"}>
                <img src={product?.imageURL} alt="Fotografía del producto" loading={"lazy"}/>
                <Link to={"/producto/" + product?.id}>
                    <h3 className={"card-name"}>{product?.name}</h3>
                </Link>
                <span className={"product-weight"}>{product.amountOfContent} {product.unitOfMeasurement}</span>
                <Link to={"producto/" + product?.id}>
                    <button> Ver más</button>
                </Link>
            </div>
        </div>

    )
}

export default Card;