import LogoAndLinks from "./layouts/fragments/LogoAndLinks";
import NavIcons from "./layouts/fragments/NavIcons";


const DesktopNavbar = () => {

    return (
        <nav className="desktop-navbar desktop">
            <LogoAndLinks/>
            <NavIcons/>
        </nav>
    )
}

export default DesktopNavbar;