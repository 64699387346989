import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import SectionList from "./fragments/SectionList";
import PagesList from "./fragments/PagesList";
import ProductOptions from "./fragments/ProductOptions";
import ContactUsIcons from "./fragments/ContactUsIcons";

interface props {
    menuRef: React.RefObject<HTMLDivElement>
    isOpen: boolean;
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PhoneSlideMenu = ({menuRef, isOpen, setMenuOpen}: props) => {
const isASection = window.location.href.includes("/#");

    return (
        <div ref={menuRef}
             className={`phone-menu ${isOpen ? 'open' : ''}`}>

            {isASection ?
                <SectionList setMenuOpen={setMenuOpen}/>
                :
                <PagesList setMenuOpen={setMenuOpen}/>
            }

            <ProductOptions setMenuOpen={setMenuOpen}/>

            <ContactUsIcons/>

            <button className="closeButton" onClick={() => setMenuOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-6 h-6 close-menu-btn">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>
        </div>
    )
};

export default PhoneSlideMenu;
