import CatalogueProduct from "./fragments/CatalogueProduct";
import React, {useContext, useEffect, useState} from "react";
import Product from "../../../../types/Product";

interface Props {
    products: Product[]
}

const Products = ({products}: Props) => {
    const [showAd, setShowAd] = useState("");
    return (
        <main className={"main-content"}>

            {/*<div className={"publicidad-container"}>*/}
            {/*    <img src="https://media.gq.com.mx/photos/5dc1b1e59c244300080d17ac/16:9/pass/spider.jpg" alt=""/>*/}
            {/*</div>*/}

            <div
                className={showAd === "saved" ? "product-alarm product-added-alarm active-alarm" : "product-alarm"}>
                Producto añadido exitosamente
            </div>

            <div
                className={showAd === "rejected" ? "product-alarm product-rejected-alarm active-alarm" : "product-alarm"}>
                El producto ya se encuentra en el carrito
            </div>


            <div className={"products-container"}>
                <div className={"catalogue-filter-title-container"}>
                    <h1>Todos los productos</h1>
                </div>

                <div className={"catalogue-products"}>
                    {
                        products.map(product => (
                            <CatalogueProduct key={product.id} product={product} setShowAd={setShowAd} showAd={showAd}/>
                        ))
                    }
                </div>
            </div>
        </main>
    )
}

export default Products;