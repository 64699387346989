import "../../../assets/styles/shoppingCar.css"
import {useEffect, useState} from "react";
import UserForm from "./layouts/UserForm";
import {CarritoDeCompras} from "../../../utils/CarritoDeCompras";
import PurchaseSummary from "./layouts/PurchaseSummary";
import ShoppingCarProductsList from "./layouts/ShoppingCarProductsList";
import {getStoredExchangeRate} from "../../../utils/ExchangeUtility";
import ProductInShoppingCar from "../../../types/ProductInShoppingCar";


export type EditQuantityInfo = {
    productId: string,
    newQuantity: number
}

export type UserInfoFormInterface = {
    isNewClient: boolean,
    name: string,
    location: string,
    zipCode: string,
    number: string,
    correo: string
}
const exchangeRate: number = getStoredExchangeRate();
const ShoppingCar = () => {
    const [carrito, setCarrito] = useState(CarritoDeCompras.getCarrito)
    const [total, setTotal] = useState(0)
    const [isAValidShoppingCar, setIsAValidShoppingCar] = useState(true)
    const [summaryChecked, setSummaryChecked] = useState(false);
    const [editQuantityInfo, setEditQuantityInfo] = useState<EditQuantityInfo | null>(null)
    const [productToDeleteById, setProductToDeleteById] = useState("")

    function handleEmptyShoppingCar() {
        setIsAValidShoppingCar(false);
        setTotal(0);
        CarritoDeCompras.vaciarCarrito();
        setCarrito([]);
        setSummaryChecked(false);
        setEditQuantityInfo(null);
        setProductToDeleteById("");
    }

    const calculateOrder = (purchaseOrder: ProductInShoppingCar[]) => {
        if (purchaseOrder.length > 0) {
            let totalMXN = 0;
            let totalUSD = 0;
            purchaseOrder.forEach(product => {
                const pieces = product.cantidad;
                const price = product.product.price;
                if (product.product.currency === "USD") {
                    totalUSD += (pieces * price * (exchangeRate * 100))
                } else if (product.product.currency === "MXN") {
                    totalMXN += pieces * price
                }
            })
            totalUSD /= 100;
            return (totalMXN + totalUSD) / 100
        }
        return 0;
    }
    const isAValidOrder = (purchaseOrder: ProductInShoppingCar[]) => {
        let isValid = true
        purchaseOrder.forEach((product) => {
            if (!product.cantidad || product.cantidad < 1) isValid = false;
        })
        return isValid;
    }
    const updatePurchaseOrder = () => {
        const currentOrder = CarritoDeCompras.getCarrito()
        setIsAValidShoppingCar(isAValidOrder(currentOrder))
        setTotal(calculateOrder(currentOrder))
        setCarrito(currentOrder)
    }

    useEffect(() => {
        if (editQuantityInfo)
            CarritoDeCompras.actualizarCantidadDeProduct(editQuantityInfo.productId, editQuantityInfo.newQuantity)

        if (productToDeleteById)
            CarritoDeCompras.eliminarProducto(productToDeleteById)

        updatePurchaseOrder()
    }, [editQuantityInfo, productToDeleteById, setCarrito]);


    return (
        <main className={"shopping-car"}>

            <div className={summaryChecked ? "shopping-car-overlay" : ""}></div>
            <ShoppingCarProductsList carrito={carrito} setCarrito={setCarrito}
                                     setProductToDeleteById={setProductToDeleteById}
                                     setEditQuantityInfo={setEditQuantityInfo} isSummaryAccepted={summaryChecked}/>

            {!summaryChecked ?
                <PurchaseSummary setSummaryChecked={setSummaryChecked}
                                 isAValidShoppingCart={isAValidShoppingCar}
                                 total={total}/>
                :
                <UserForm setSummaryChecked={setSummaryChecked} setCarrito={setCarrito}
                          handleEmptyShoppingCar={handleEmptyShoppingCar} total={total}/>
            }
        </main>
    )
}


export default ShoppingCar;