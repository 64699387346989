import {NUMBER_OF_DECIMALS} from "../App";
import {processPrice} from "./PricesUtility";


export function exchangeRoutine() {
    let exchangeExistInStorage = !!localStorage.getItem("exchange")
    let dateExistInStorage = localStorage.getItem("lastExchangeDate")
    if (dateExistInStorage) {

        checkAndUpdateCurrencyValues()
    }
    if (!exchangeExistInStorage) updateExchangeRate();
    if (!dateExistInStorage) updateDate();
}

const FIXER_API_KEY = process.env.FIXER_API_KEY;
const getStoredDate = () => new Date(JSON.parse(localStorage.getItem("lastExchangeDate") || ""))
export const getStoredExchangeRate = (): number => {

    return Number(JSON.parse(localStorage.getItem("exchange") || "0"))
}
const updateDate = () => localStorage.setItem("lastExchangeDate", JSON.stringify(new Date()));


export const updateExchangeRate = async () => {
    try {
        const newExchange = await getExchangeFromApi();
        if (newExchange !== -1) {
            localStorage.setItem("exchange", JSON.stringify(newExchange));
        }
    } catch (error) {
        console.log("Error fetching exchange rate:", error);
    }
}

export const getExchangeFromApi = async () => {
    try {
        const response = await fetch(`https://data.fixer.io/api/latest?access_key=${FIXER_API_KEY}&base=USD&symbols=MXN`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const result = await response.json();
        console.log(result.rates["MXN"]);
        return result.rates["MXN"].toFixed(NUMBER_OF_DECIMALS);
    } catch (error) {
        console.log(error)
    }
    return -1;
};

export function checkAndUpdateCurrencyValues() {
    let storedDate = getStoredDate();
    let todayDate = new Date();
    if (todayDate.getUTCFullYear() !== storedDate.getUTCFullYear()) {
        updateExchangeRate()
        updateDate();
    } else if (todayDate.getUTCMonth() !== storedDate.getUTCMonth()) {
        updateExchangeRate()
        updateDate();
    } else if (todayDate.getUTCDate() !== storedDate.getUTCDate()) {
        updateExchangeRate()
        updateDate();
    }
}

export function convertUSDToMXN(value: number, exchangeRate: number) {
    let storedExchangeRateWithoutDecimals = exchangeRate * 100;
    return (value * storedExchangeRateWithoutDecimals / 100);
}

export function exchangeProcess(price: number, quantity: number, exchangeRate: number) {
    const priceInMXN = convertUSDToMXN(price * quantity, exchangeRate) * 100
    return processPrice((priceInMXN / 10000).toFixed(2));
}

