import {useEffect, useState} from "react";
import {UserInfoFormInterface} from "../ShoppingCar";
import WhatsappIcon from "../../../../assets/images/WhatsappIcon.svg";
import ProductInShoppingCar from "../../../../types/ProductInShoppingCar";
import {CarritoDeCompras} from "../../../../utils/CarritoDeCompras";


interface props {
    setSummaryChecked: React.Dispatch<React.SetStateAction<boolean>>
    setCarrito: React.Dispatch<React.SetStateAction<ProductInShoppingCar[]>>
    handleEmptyShoppingCar: () => void
    total: number
}

const UserForm = ({setSummaryChecked, setCarrito, handleEmptyShoppingCar, total}: props) => {
    const [userForm, setUserForm] = useState<UserInfoFormInterface>({
        name: "",
        correo: "",
        location: "",
        zipCode: "",
        number: "",
        isNewClient: false
    })
    const [isValidForm, setIsValidForm] = useState(false)
    const [orderButtonMessage, setOrderButtonMessage] = useState('')

    function handleOrder() {
        const carrito = CarritoDeCompras.getCarrito();
        const listMessage = createOrderMessage(userForm, carrito)
        window.open(`https://wa.me/523521174644?text=${listMessage}`)?.focus();
        handleEmptyShoppingCar();
    }

    function createOrderMessage(formulario: UserInfoFormInterface, products: ProductInShoppingCar[]): string {
        let message = "¡Hola! Me gustaría realizar un pedido, ";
        if (formulario.isNewClient) {
            message += (`*SOY* cliente *nuevo*. Anexo mis datos \n \nNombre: ${formulario.name} \nNúmero de teléfono: ${formulario.number}\nCorreo electrónico: ${formulario.correo} \nCódigo postal: ${formulario.zipCode} \nDirección: ${formulario.location}\n \n`)
        }
        if (!formulario.isNewClient) {
            message += "Soy cliente *activo*. Anexo mis datos \n \n"
                + `Nombre: ${formulario.name} \nNúmero: ${formulario.number} \n \n`
        }

        products.map(product => {
            const unit = product.product.unitOfMeasurement;
            let kgs = 0
            if (unit.toLowerCase() === "kg") {
                kgs = product.product.amountOfContent * product.cantidad;
                message += `${product.product.name} : ${kgs} Kg\n`
            } else {
                message += `${product.product.name} : ${product.cantidad} ${product.cantidad > 1 ? "pzas" : "pza"} \n`
            }

        })
        return encodeURIComponent(message)
    }

    function handleNumberInput(event: React.ChangeEvent<HTMLInputElement>) {
        setUserForm({...userForm, number: event.target.value})
    }


    useEffect(() => {
        setIsValidForm(false)
        const getTextToOrderButton = (): string => {

            if (userForm.name.length === 0) return 'Falta nombre'

            if (!userForm.isNewClient) {
                if (userForm.number.length !== 10) return 'Número inválido'
            }

            if (userForm.isNewClient) {
                if (userForm.location.length < 10) return 'Ubicación vacía o demasiado pequeña'
                if (userForm.zipCode.length !== 5) return 'Código postal inválido'
                if (userForm.number.length !== 10) return 'Número inválido'
                if (!userForm.correo.includes('@')) return 'Correo vacío o inválido'
            }

            setIsValidForm(true)
            return 'Hacer pedido por WhatsApp'
        }

        setOrderButtonMessage(getTextToOrderButton())
    }, [userForm]);


    return (
        <aside className={"user-info-form"}>
            <div className={"user-info-form-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" className="back-button" onClick={() => setSummaryChecked(false)}>
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"/>
                </svg>


                <h2>¿Eres cliente nuevo?</h2>

                <div className={"formulario-input-options"}>
                    <div className={"cliente-nuevo-option"}>
                        <input name={"client-type"} type="radio" id={"clienteNuevo"}
                               onClick={() => setUserForm({...userForm, isNewClient: true})}/>
                        <label htmlFor="clienteNuevo">Sí</label>
                    </div>
                    <div className={"cliente-nuevo-option"}>
                        <input name={"client-type"} type="radio" id={"clienteViejo"}
                               onClick={() => setUserForm({...userForm, isNewClient: false})} defaultChecked={true}/>
                        <label htmlFor="clienteViejo">No</label>
                    </div>
                </div>


                {userForm.isNewClient ?
                    <div className={"clientForm"}>

                        <input type="text" placeholder={"Nombre"} name={"clientName"} required={true}

                               onChange={event => {
                                   setUserForm({...userForm, name: event.target.value})
                               }} value={userForm.name}/>

                        <textarea name={"ubicacion"} placeholder={"Dirección"} required={true}
                                  className={userForm.location.length < 10 ? "clientFormWrongInput" : "clientFormCorrectInput"}
                                  onChange={event => setUserForm({...userForm, location: event.target.value})}/>

                        <input type="text"
                               placeholder={"Código postal"}
                               onChange={event => setUserForm({...userForm, zipCode: event.target.value})}
                        />

                        <input type="number" name={"telDelCliente"} placeholder={"Num de teléfono"}
                               required={true}
                               onChange={handleNumberInput}
                               className={userForm.number.length === 10 ? "clientFormCorrectInput" : "clientFormWrongInput"}
                        />

                        <input type="email" name={"correoDelCliente"} required={false}
                               className={userForm.correo.length === 0 ? "clientFormWrongInput" : "clientFormCorrectInput"}
                               placeholder={"Introduce tu correo electrónico"}
                               onChange={event => setUserForm({...userForm, correo: event.target.value})}/>

                    </div>

                    :

                    <div className={"clientForm"}>
                        <input type="text" placeholder={"Nombre"} value={userForm.name}

                               onChange={event => {
                                   setUserForm({...userForm, name: event.target.value})
                               }}/>

                        <input type="number" name={"telDelCliente"} placeholder={"Num de teléfono"}
                               required={true}
                               onChange={handleNumberInput}
                               className={userForm.number.length === 10 ? "clientFormCorrectInput" : "clientFormWrongInput"}
                        />
                    </div>
                }

                <a
                    href={"#"}
                    className={isValidForm ? "order-button" : "order-button disabled-button"}
                    onClick={handleOrder}>
                    <img src={WhatsappIcon} alt=""/>
                    {orderButtonMessage}
                </a>
            </div>
        </aside>
    )
}

export default UserForm;
