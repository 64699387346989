import React from "react";

interface props {

    tittle: string,
    description: string[],
    image: string
    isList: boolean
}

const NosotrosCard: React.FC<props> = ({tittle, description, image, isList}) => {

    return (
        <div className="nosotros-card">
            <div className={"nosotros-info"}>
                <strong className={'nosotros-info-title'}>{tittle}</strong>

                {isList ?
                    <ul>
                        {description.map((paragraph, index) => <li key={index}>{paragraph}</li>)}
                    </ul>
                    :
                    description.map((paragraph, index) => <p key={index}>{paragraph}</p>)}


            </div>

            <img src={image} draggable={"false"}
                 alt="Imágen representativa de nuestros valores, creencias y aspiraciones; en ella salen varios trabajadores que representan dichos valores"
                 className={"nosotros-img"}/>
        </div>
    )
}

export default NosotrosCard;