import {useState} from "react";
import {EditQuantityInfo} from "../../ShoppingCar";
import ProductInShoppingCar from "../../../../../types/ProductInShoppingCar";
import {Link} from "react-router-dom";
import ShoppingCarItemInput from "./ShoppingCarItemInput";
import ShoppingCarItemPrice from "./ShoppingCarItemPrice";


interface props {
    productInCarrito: ProductInShoppingCar,
    setProductToDeleteById: React.Dispatch<React.SetStateAction<string>>,
    setEditQuantityInfo: React.Dispatch<React.SetStateAction<EditQuantityInfo | null>>,
    isHide: boolean,
    exchangeRate: number
}

const ShoppingCarItem = ({
                             productInCarrito,
                             setProductToDeleteById,
                             setEditQuantityInfo,
                             isHide,
                             exchangeRate
                         }: props) => {

    const [newProductQuantity, setNewProductQuantity] = useState(productInCarrito.cantidad)

    function handleSubtract() {
        if (!newProductQuantity || newProductQuantity < 1) {
            return;
        } else {
            const editQuantityInfo: EditQuantityInfo = {
                productId: productInCarrito.product.id,
                newQuantity: newProductQuantity - 1,
            }
            setEditQuantityInfo(editQuantityInfo)
            setNewProductQuantity(editQuantityInfo.newQuantity)
        }
    }

    function handlePlus() {

        if (!newProductQuantity) {
            setNewProductQuantity(1)
            setEditQuantityInfo({productId: productInCarrito.product.id, newQuantity: 1})
        } else {
            const editQuantityInfo: EditQuantityInfo = {
                productId: productInCarrito.product.id,
                newQuantity: newProductQuantity + 1,
            }
            setEditQuantityInfo(editQuantityInfo)
            setNewProductQuantity(editQuantityInfo.newQuantity)
        }

    }

    function handleQuantityChange(event: React.ChangeEvent<HTMLInputElement>) {
        const newValue = parseInt(event.target.value);
        const productId = productInCarrito.product.id;
        const editQuantityInfo: EditQuantityInfo = {
            productId: productId,
            newQuantity: newValue,
        }

        setEditQuantityInfo(editQuantityInfo)
        setNewProductQuantity(newValue)
    }

    function handleDeleteProduct() {
        setProductToDeleteById(productInCarrito.product.id)
    }

    return (
        <div className={"product-in-shopping-car"}>
            <div className={isHide ? "purchase-body cart-product-hide" : "purchase-body "}>

                <img className={"purchase-img"}
                     src={productInCarrito.product.imageURL}
                     alt="Imagen representativa del producto"/>

                <span className={"purchase-name"}>
                        <strong>{productInCarrito.product.name}</strong>
                </span>

                <Link to={"/productos/" + productInCarrito.product.id} className={"show-product-details"}>
                    + detalles del producto
                </Link>


                <ShoppingCarItemInput newProductQuantity={newProductQuantity}
                                      handleQuantityChange={handleQuantityChange}
                                      handleDeleteProduct={handleDeleteProduct} handleSubtract={handleSubtract}
                                      handlePlus={handlePlus}/>


                <ShoppingCarItemPrice productInCarrito={productInCarrito} exchangeRate={exchangeRate}/>

                <span className={"purchase-delete"} onClick={() => handleDeleteProduct()}>
                            Eliminar del carrito
                </span>
            </div>
        </div>
    )
}

export default ShoppingCarItem;