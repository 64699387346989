import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {ReactComponent as Flecha} from "../../assets/images/flecha.svg"


interface props {
    tittle: string
    linksName: string[]
    linksURL: string[]
}

const path = window.location.pathname;
const LinksContainer = ({tittle, linksName, linksURL}: props) => {

    const [isContainerOpen, setIsContainerOpen] = useState(false)
    const navigate = useNavigate();
    return (
        <div className={"links-component-container"} onMouseOver={() => setIsContainerOpen(true)}
             onMouseLeave={() => setIsContainerOpen(false)}>

            <div className={"trigger-container"}>
                <Flecha className={isContainerOpen ? "arrow active" : "arrow"}/>
                <span className={isContainerOpen ? "pages-tittle active" : "pages-tittle"}>{tittle}</span>
            </div>

            <div className={isContainerOpen ? "links-container display-links" : "links-container"}>

                {linksName.map((name, index) => {
                    return <Link to={linksURL[index]} className={"nav-link"} key={index}>{name}</Link>
                })}
            </div>
        </div>
    )
}

export default LinksContainer;